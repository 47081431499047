import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useEffect } from 'react';

export function usePaypal() {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  useEffect(() => {
    if (CLIENT_ID) {
      const laodPaypalScript = async () => {
        paypalDispatch({
          type: 'resetOptions',
          value: {
            'client-id': CLIENT_ID,
            currency: 'EUR',
          },
        });
        paypalDispatch({ type: 'setLoadingStatus', value: 'pending' });
      };

      if (!window.paypal) {
        laodPaypalScript();
      }
    }
  }, [CLIENT_ID, paypalDispatch]);
}
