import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const DonationForm = ({
  email,
  setEmail,
  prenom,
  setPrenom,
  nom,
  setNom,
  adresse,
  setAdresse,
  codePostal,
  setCodePostal,
  pays,
  setPays,
  telephone,
  setTelephone,
  errors,
}) => {
  return (
    <div className=' flex space-y-2 flex-col'>
      <input
        type='email'
        placeholder='Email'
        className={`w-full p-2 border rounded ${
          errors.email ? 'border-red-500' : ''
        }`}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type='text'
        placeholder='Prénom'
        className={`w-full p-2 border rounded ${
          errors.prenom ? 'border-red-500' : ''
        }`}
        value={prenom}
        onChange={(e) => setPrenom(e.target.value)}
      />
      <input
        type='text'
        placeholder='Nom'
        className={`w-full p-2 border rounded ${
          errors.nom ? 'border-red-500' : ''
        }`}
        value={nom}
        onChange={(e) => setNom(e.target.value)}
      />
      <input
        type='text'
        placeholder='Adresse'
        className={`w-full p-2 border rounded ${
          errors.adresse ? 'border-red-500' : ''
        }`}
        value={adresse}
        onChange={(e) => setAdresse(e.target.value)}
      />
      <input
        type='text'
        placeholder='Code postal'
        className={`w-full p-2 border rounded ${
          errors.codePostal ? 'border-red-500' : ''
        }`}
        value={codePostal}
        onChange={(e) => setCodePostal(e.target.value)}
      />
      <input
        type='text'
        placeholder='Pays'
        className={`w-full p-2 border rounded ${
          errors.pays ? 'border-red-500' : ''
        }`}
        value={pays}
        onChange={(e) => setPays(e.target.value)}
      />
      {/* <input
        type='tel'
        placeholder='Téléphone'
        className={`w-full p-2 border rounded ${
          errors.telephone ? 'border-red-500' : ''
        }`}
        value={telephone}
        onChange={(e) => setTelephone(e.target.value)}
      /> */}

      <PhoneInput
        country={'fr'} // Default country
        value={telephone}
        onChange={(phone) => setTelephone(phone)}
        placeholder='Téléphone'
        className={`flex-1   ${errors.telephone ? 'phone-error' : ''}`}
      />
    </div>
  );
};

export default DonationForm;
