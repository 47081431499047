import DonationOption from '../componants/donation/DonationOption';
import DonationInfo from '../componants/donation/DonationInfo';

const Donation = () => {
  return (
    <main>
      <DonationOption />
      <DonationInfo />
    </main>
  );
};

export default Donation;
