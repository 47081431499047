import React, { useState } from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { usePaypal } from '../hooks/usePaypal';
import { useNavigate, useParams } from 'react-router-dom';

const PaymentPage = () => {
  const navigate = useNavigate();
  const { amount } = useParams();
  const [order, setOrder] = useState({ isPaid: false, amount: amount });

  usePaypal(order);

  return (
    <main>
      <div className=' min-h-[80vh] py-8 flex flex-col items-center justify-center bg-gray-100'>
        <div className='w-full max-w-md bg-white shadow-sm rounded-lg p-6'>
          <h2 className='text-2xl font-bold text-gray-800 text-center mb-4'>
            Don par PayPal
          </h2>
          <p className='text-gray-600 text-center mb-6'>
            Montant du don{' '}
            <span className=' font-bold text-lg'>{order.amount}</span> €
          </p>

          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: { value: Number(order.amount) },
                  },
                ],
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                setOrder({ ...order, isPaid: true });
                navigate('/payement/success');
              });
            }}
            onError={(err) => console.error('Erreur PayPal :', err)}
          />
        </div>
      </div>
    </main>
  );
};

export default PaymentPage;
