import React from 'react';
import { useNavigate } from 'react-router-dom';

const CheqSendSuccess = () => {
  const navigate = useNavigate();
  return (
    <main>
      <div className='min-h-[80vh] bg-gray-100 flex items-center justify-center'>
        <div className='bg-white shadow-sm rounded-lg p-8 max-w-xl text-center'>
          <svg
            className='w-16 h-16 text-[#0c40b7] mx-auto'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='currentColor'
          >
            <path
              fillRule='evenodd'
              d='M2.25 12a9.75 9.75 0 1119.5 0 9.75 9.75 0 01-19.5 0zm10.47-2.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06L11.25 11.31l4.47 4.47a.75.75 0 101.06-1.06l-5-5z'
              clipRule='evenodd'
            />
          </svg>
          <h1 className='text-2xl font-bold text-gray-800 mt-4'>
            Merci pour votre générosité !
          </h1>
          <p className='text-gray-600 mt-4'>
            Nous vous remercions pour votre intention généreuse de soutenir le
            CEISNA. Votre demande de don par chèque a été enregistrée et
            transmise.
          </p>
          <p className='text-gray-600 mt-2'>
            Un membre de notre équipe vous contactera bientôt pour finaliser la
            transaction par chèque.
          </p>
          <button
            onClick={() => navigate('/')}
            className='mt-6 bg-[#0c40b7] text-white px-6 py-2 rounded-full shadow-md hover:bg-[#0c3fb7c0]'
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    </main>
  );
};

export default CheqSendSuccess;
