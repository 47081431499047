const DonationInfo = () => {
  return (
    <div className='bg-[#00075e] text-white py-16  border-b-2 border-b-white'>
      <div className='container mx-auto px-6'>
        <h2 className=' text-2xl md:text-3xl font-extrabold text-center mb-10'>
          Pourquoi faire un don à CEISNA ?
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
          {/* Colonne 1 : Soutenir les femmes et les enfants */}
          <div>
            <h3 className='text-xl font-bold mb-4'>
              Soutenir les femmes et les enfants
            </h3>
            <p className='text-sm leading-relaxed'>
              Les fonds récoltés par CEISNA permettent de financer des
              initiatives en faveur des femmes et des enfants vulnérables. Vos
              dons servent à :
            </p>
            <ul className='mt-4 list-disc list-inside text-sm leading-relaxed'>
              <li>
                Offrir des soins médicaux essentiels aux enfants en détresse.
              </li>
              <li>
                Fournir un soutien psychologique et social aux femmes victimes
                de violences.
              </li>
              <li>
                Assurer l'accès à la nutrition et à l'éducation dans les zones
                défavorisées.
              </li>
            </ul>
          </div>

          {/* Colonne 2 : Renforcer les organisations locales */}
          <div>
            <h3 className='text-xl font-bold mb-4'>
              Renforcer les organisations locales
            </h3>
            <p className='text-sm leading-relaxed'>
              CEISNA collabore avec des ONG et des organisations locales pour
              répondre aux crises humanitaires et renforcer les capacités des
              acteurs de terrain.
            </p>
            <p className='text-sm leading-relaxed mt-4'>
              Les dons permettent de :
            </p>
            <ul className='mt-4 list-disc list-inside text-sm leading-relaxed'>
              <li>
                Former les professionnels de santé et les travailleurs
                humanitaires.
              </li>
              <li>
                Fournir des ressources médicales et logistiques pour des
                interventions rapides.
              </li>
              <li>
                Soutenir des projets de long terme pour un impact durable.
              </li>
            </ul>
          </div>

          {/* Colonne 3 : Confiance et transparence */}
          <div>
            <h3 className='text-xl font-bold mb-4'>
              Confiance et transparence
            </h3>
            <p className='text-sm leading-relaxed'>
              CEISNA garantit une gestion responsable et transparente des dons.
              96 % des fonds sont alloués directement aux missions sociales, et
              vos données personnelles sont protégées.
            </p>
            <div className='mt-6'>
              <div className='flex items-center justify-center'>
                <div className='w-24 h-24 bg-green-500 rounded-full flex items-center justify-center text-white text-lg font-bold'>
                  96%
                </div>
              </div>
              <p className='text-sm text-center mt-4'>
                Utilisation des fonds : 96% pour les missions sociales.
              </p>
            </div>
            <p className='text-sm leading-relaxed mt-6 text-center'>
              Vos dons aident à sauver des vies et à créer des opportunités pour
              les communautés africaines.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationInfo;
