import BeatLoader from 'react-spinners/BeatLoader';

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#114cb3',
  width: 'auto',
};

const Spinner = ({ loading, color = '#114cb3' }) => {
  return (
    <BeatLoader
      color={color}
      loading={loading}
      cssOverride={override}
      size={20}
      data-testid='loader'
    />
  );
};

export default Spinner;
