import React from 'react';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { usePaypalScription } from '../hooks/usePaypalScription';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Spinner from '../componants/utilities/Spinner';

const CheckOutPaypalSubscription = () => {
  // Retrieve the search parameters from the URL
  const [searchParams] = useSearchParams();
  const amount = searchParams.get('amount');
  const navigate = useNavigate();

  // Fetch the productId from URL params
  const { productId } = useParams();

  const { isPending } = usePaypalScription();

  const displayAmount = amount ? parseFloat(amount).toFixed(2) : 'N/A';

  if (isPending) {
    return (
      <main>
        <div className=' w-full justify-center flex items-center'>
          <Spinner />
        </div>
      </main>
    );
  }

  return (
    <main>
      <div className='min-h-[70vh] flex flex-col items-center justify-center bg-gray-100'>
        <div className='w-full max-w-md bg-white shadow-sm rounded-lg p-6'>
          <h2 className='text-2xl font-bold text-gray-800 text-center mb-4'>
            Abonnement Mensuel
          </h2>
          <p className='text-gray-600 text-center mb-6'>
            Contribuez mensuellement pour soutenir notre cause. Merci !
          </p>

          {/* Display the amount from the URL */}
          <div className='text-center mb-6'>
            <p className='text-xl '>
              Montant de l'abonnement:{' '}
              <span className='font-semibold'>{displayAmount} €</span>
            </p>
          </div>

          {/* PayPal Buttons for subscription */}
          <PayPalButtons
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id: productId,
                // You could potentially pass the amount here if needed for processing
              });
            }}
            onApprove={(data) => {
              navigate('/payement/success');
            }}
            onError={(err) => {
              console.error('Erreur :', err);
              alert('Une erreur est survenue lors du paiement.');
            }}
          />
        </div>
      </div>
    </main>
  );
};

export default CheckOutPaypalSubscription;
