import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import brain_svg from './../../public/images/book.svg';
import logo from './../../public/images/logo.png';
import { dropdown } from './dropdown';

const MobileMenu = ({ openModal, setOpenModal }) => {
  return (
    <div
      onClick={() => setOpenModal(false)}
      className={`fixed z-[999] transition-all duration-500 bg-[#00000068] w-full -top-0 ${
        openModal ? 'translate-x-0' : '-translate-x-[50rem]'
      }   h-[100vh] md:hidden`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=' pt-2 z-[99999] bg-[#00075e] relative w-[70%] h-full '
      >
        <ul className=' space-y-2 text-[#fff] w-full flex flex-col  px-2 items-start  lg:w-[80%] capitalize mx-auto  justify-between text-[1.2rem]'>
          <li className='py-[0.5rem] px-2 text-[0.9rem]  w-full   transition-all border-b-[0.1px] border-b-[#ffffff6d]'>
            <Link onClick={() => setOpenModal(false)} to='/'>
              Accueil
            </Link>
          </li>
          <li className='py-[0.5rem] px-2 text-[0.9rem]  w-full   transition-all border-b-[0.1px] border-b-[#ffffff6d]'>
            <Link onClick={() => setOpenModal(false)} to='/activite'>
              Commissions
            </Link>
          </li>
          <li className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all border-b-[0.1px] border-b-[#ffffff6d]'>
            <Link onClick={() => setOpenModal(false)} to='/ressources'>
              Séances
            </Link>
          </li>
          <li className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all  border-b-[0.1px] border-b-[#ffffff6d]'>
            <Link onClick={() => setOpenModal(false)} to='/devenir-membre'>
              Membres
            </Link>
          </li>
          <li className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all border-b-[0.1px] border-b-[#ffffff6d]'>
            <Link onClick={() => setOpenModal(false)} to='/actualites'>
              Actualités
            </Link>
          </li>
          <li className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all border-b-[0.1px] border-b-[#ffffff6d]'>
            <Link
              onClick={() => setOpenModal(false)}
              target='_blank'
              rel='noopener noreferrer'
              to='https://academieceisna.org/'
            >
              Formation
            </Link>
          </li>
          <li className='py-[0.5rem] px-2 text-[0.9rem]  w-full  transition-all border-b-[0.1px] border-b-[#ffffff6d]'>
            <Link onClick={() => setOpenModal(false)} to='/don'>
              Don
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const HeaderMenu = () => {
  const [isActive, setIsActive] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const timeoutRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [menuIndex, setMenuIndex] = useState(0);

  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuIndex(index);
    setIsAnimating(false);
    setIsActive(true);
  };

  const handleMouseLeave = () => {
    setIsAnimating(true);
    timeoutRef.current = setTimeout(() => {
      setIsActive(false);
      setIsAnimating(false);
    }, 800);
  };

  return (
    <div onMouseLeave={handleMouseLeave} className='relative overflow-hidden'>
      <MobileMenu openModal={openModal} setOpenModal={setOpenModal} />
      <div className='text-white h-[70px] relative z-30 bg-white md:bg-[#00075e] '>
        <div className=' py-4 w-[99%] mx-auto justify-between flex md:hidden h-full items-center '>
          <Link to='/'>
            <img
              src={logo}
              alt='image'
              className=' py-3 h-[90px] w-[200px] object-cover'
            />
          </Link>
          <button onClick={() => setOpenModal(true)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className=' w-8 h-8 text-[#00075e]'
            >
              <path
                fill-rule='evenodd'
                d='M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z'
                clip-rule='evenodd'
              />
            </svg>
          </button>
        </div>
        <ul className=' hidden md:flex  items-center w-[95%] lg:w-[80%] capitalize mx-auto  justify-between text-[1.2rem]'>
          <li
            onMouseEnter={() => handleMouseEnter(0)}
            className='py-[0.5rem] px-2 lg:px-[3rem]   transition-all hover:bg-[#003f97] menu__item '
          >
            <Link to='/'>Accueil</Link>
          </li>

          <li
            onMouseEnter={() => handleMouseEnter(1)}
            className='py-[0.5rem] px-2 lg:px-[3rem]   transition-all hover:bg-[#003f97] menu__item '
          >
            <Link to='/activite'>Commissions</Link>
          </li>
          <li
            onMouseEnter={() => handleMouseEnter(2)}
            className='py-[0.5rem] px-2 lg:px-[3rem] transition-all hover:bg-[#003f97] menu__item'
          >
            <Link to='/ressources'>Séances</Link>
          </li>
          <li
            onMouseEnter={() => handleMouseEnter(3)}
            className='py-[0.5rem] px-2 lg:px-[3rem] transition-all hover:bg-[#003f97] menu__item'
          >
            <Link to='/devenir-membre'>Membres</Link>
          </li>
          <li
            onMouseEnter={() => handleMouseEnter(4)}
            className='py-[0.5rem] px-2 lg:px-[3rem] transition-all hover:bg-[#003f97] menu__item'
          >
            <Link to='/actualites'>Actualités</Link>
          </li>
        </ul>
      </div>
      <div
        className={`submenu ${isActive ? 'menu__actif' : ''} ${
          isAnimating ? 'menu__inactif' : ''
        } bg-[#003f97]  absolute z-20    `}
      >
        <ul className=' w-[95%] 2xl:w-[80%] mx-auto pt-1 pb-6 gap-16 grid grid-cols-4 text-white relative z-50'>
          <li>
            <div className='h-[150px] w-[200px] xl:w-[250px]'>
              <img
                className='h-full w-full object-cover'
                src={dropdown[menuIndex].banner.images}
                alt=''
              />
            </div>
            <div className=' pt-3 '>
              <p className=' text-[0.8rem]'>
                {dropdown[menuIndex].banner.imagedescrition}
              </p>
              <button
                onClick={() => navigate(dropdown[menuIndex].banner.url)}
                className=' flex items-center mt-1'
              >
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className=' h-3'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5'
                    />
                  </svg>
                </span>{' '}
                <span> Savoir plus</span>
              </button>
            </div>
          </li>
          <li className=' relative z-[9999] col-span-2 '>
            <h2 className=' text-[2rem]'>{dropdown[menuIndex].pageTitle} </h2>
            <ul className='  relative  z-50'>
              {dropdown[menuIndex].links.map((link, i) => (
                <li key={i} className=' relative z-40'>
                  <Link
                    to={link.url}
                    className='flex submenu_items relative z-50  items-center space-x-1'
                  >
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className=' h-3'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5'
                        />
                      </svg>
                    </span>
                    <span>{link.title} </span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className=' border-[2px] p-2 border-dashed border-[#ffff]'>
            <div className=' flex  items-start space-x-2'>
              <div>
                <img src={brain_svg} alt='' className=' h-[3rem]' />
              </div>
              <h4 className=' text-[1.2rem]'>{dropdown[menuIndex].subject}</h4>
            </div>
            <ul className=' flex flex-col'>
              {dropdown[menuIndex].subjetLinks.map((link, i) => (
                <li key={i}>
                  <Link
                    to={link.url}
                    className='flex submenu_items relative z-50  items-center space-x-1'
                  >
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className=' h-3'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5'
                        />
                      </svg>
                    </span>
                    <span>{link.title} </span>{' '}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderMenu;
