import React from 'react';
import video from '../../public/images/bgvideo.mp4';

const BackgroundVideo = () => {
  return (
    <div className='relative  h-[60vh]  lg:h-[69vh]   overflow-hidden'>
      <video
        className='absolute top-0 left-0 w-full h-full object-cover'
        src={video}
        type='video/mp4'
        autoPlay
        loop
        muted
      />
      <div className='relative z-10 flex flex-col items-center justify-center h-full text-white'></div>
    </div>
  );
};

export default BackgroundVideo;
